<template>
  <!-- <div class="row mb-5">
    <div class="col">
      <form action="" @submit.prevent="search()">
        <div class="input-group mb-3">
          <select
            v-model="searchType"
            id="searchTypeSelect"
            @change="changeSerchType()"
          >
            <option value="name">會員暱稱</option>
            <option value="mobile">手機號碼</option>
            <option value="member_id">會員帳號</option>
          </select>
          <input
            type="text"
            class="form-control"
            placeholder="請輸入..."
            v-model="searchKeyword"
          />
          <button class="btn btn-outline-secondary" type="submit">
            快速搜尋
          </button>
        </div>
      </form>

      <button
        class="btn btn-danger"
        type="button"
        v-if="searching"
        @click="noSearch()"
      >
        <i class="bi bi-x-circle"></i>
        取消搜尋
      </button>
    </div>
    <div class="col"></div>
  </div> -->

  <div>
    <h2>會員管理</h2>

    <div class="mb-5">
      <div class="d-flex">
        <Multiselect
          v-model="searchMemberDocId"
          mode="single"
          :searchable="true"
          :options="memberOptionList"
        />
        <button class="btn btn-outline-secondary" @click="searchByDocId()">
          快速搜尋
        </button>
      </div>
      <div class="fs-6 text-secondary">
        (搜尋依照：會員暱稱 - 手機號碼 - 會員帳號)
      </div>

      <button
        class="btn btn-danger mt-3"
        type="button"
        v-if="searching"
        @click="noSearch()"
      >
        <i class="bi bi-x-circle"></i>
        取消搜尋
      </button>
    </div>

    <router-link
      class="btn btn-success mb-3"
      :to="`/admin/member/insert`"
      v-if="onlyView === false"
    >
      新增會員
    </router-link>

    <div class="table-responsive-lg">
      <table class="table table-bordered table-hover">
        <thead class="sticky-top bg-light">
          <tr>
            <th width="50">序號</th>
            <th>會員帳號</th>
            <th width="120">會員暱稱</th>
            <th>服務店家</th>
            <th>編號藝名</th>
            <th width="120">手機號碼</th>
            <th width="120">生日</th>
            <!-- <th width="120">會員編號</th>
            <th width="100">上線編號</th> -->
            <th width="80">下一線<br />會員數</th>
            <th width="100">註冊時間</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr
            v-for="(m, index) in showMembers"
            :key="m.id"
            :class="m.data.status"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ m.data.member_id }}</td>
            <td>
              <span class="badge bg-danger" v-if="m.data.status === 'delete'"
                >已停用</span
              >

              <div>{{ m.data.name }}</div>
              <div
                class="badge bg-secondary"
                v-if="m.data.agent && m.data.agent === true"
              >
                經紀人
              </div>
              <div
                class="badge bg-warning"
                v-else-if="
                  m.data.role == 'agentCompanyA' ||
                  m.data.role == 'agentCompanyB'
                "
              >
                <span v-if="m.data.role == 'agentCompanyA'"
                  >合作經紀公司 A</span
                >
                <span v-if="m.data.role == 'agentCompanyB'"
                  >合作經紀公司 B</span
                >
              </div>
            </td>
            <td>{{ m.data.store }}</td>
            <td>{{ m.data.numberStageName }}</td>
            <td>{{ m.data.mobile }}</td>
            <td>
              <div v-if="m.data.birthday">
                {{ m.data.birthday.toDate().toLocaleDateString() }}
              </div>
            </td>
            <!-- <td>
              <span class="id_show" :title="m.id">{{ m.id }}</span>
            </td>
            <td>
              <span class="id_show" :title="m.data.parentMemberId">{{
                m.data.parentMemberId
              }}</span>
            </td> -->
            <td>
              <div v-if="m.data.nextLevel1MembersId">
                {{ Object.keys(m.data.nextLevel1MembersId).length }}
              </div>
              <div v-else>0</div>
            </td>
            <td>
              <div v-if="m.data.registerTime">
                {{ m.data.registerTime.toDate().toLocaleDateString() }}
              </div>
            </td>
            <td>
              <!-- 可以管理者權限 -->
              <div v-if="onlyView === false">
                <!-- 如果是停用會員 -->
                <div v-if="m.data.status && m.data.status === 'delete'">
                  <router-link
                    :to="`/admin/member/view/${m.id}`"
                    class="btn btn-info text-white mx-1"
                  >
                    <i class="bi bi-eye"></i> 查看
                  </router-link>
                  <button
                    class="btn btn-success"
                    @click="
                      statusNormalMember(
                        m.id,
                        m.data.parentMemberId,
                        m.data.name
                      )
                    "
                  >
                    <i class="bi bi-person-check-fill"></i> 重啟
                  </button>
                </div>

                <!-- 正常會員 -->
                <div v-else>
                  <router-link
                    :to="`/admin/member/update/${m.id}`"
                    class="btn btn-info text-white mx-1"
                  >
                    <i class="bi bi-pencil-fill"></i> 編輯
                  </router-link>
                  <button
                    class="btn btn-danger mx-1"
                    v-if="m.data.status !== 'delete'"
                    @click="
                      deleteMember(m.id, m.data.parentMemberId, m.data.name)
                    "
                  >
                    <i class="bi bi-person-x"></i> 停用
                  </button>
                </div>
              </div>

              <!-- 只能瀏覽的權限 -->
              <div v-else-if="onlyView === true">
                <router-link
                  :to="`/admin/member/view/${m.id}`"
                  class="btn btn-info text-white mx-1"
                >
                  <i class="bi bi-eye"></i> 查看
                </router-link>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="text-center">
        <button
          type="button"
          class="btn btn-secondary loadmore"
          v-if="lastDoc && showMembers.length >= 20"
          @click="loadMore()"
        >
          讀取更多資料
        </button>
      </div>
    </div>
  </div>

  <!-- <pre>{{ showMembers }}</pre> -->
</template>

<style scoped>
table {
  text-align: center;
  word-break: keep-all;
}

table th,
table td {
  vertical-align: middle;
}

tr.delete {
  background-color: #f8d7da;
}

#searchTypeSelect {
  border-color: #ced4da;
  color: #6c757d;
  padding: 0 10px;
}

.id_show {
  width: 100px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  /* background-color: #6c757d;
  color: #fff;
  padding: 5px; */
}
</style>

<script>
import { memberService } from "../../../services/memberService.js";
import { variableService } from "../../../services/variableService.js";
import Multiselect from "@vueform/multiselect";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      lastDoc: null,
      searchKeyword: "",
      //allMembers: {}, // 預設顯示的全部資料
      showMembers: {}, // 用來展示於畫面上的資料
      // searchMembers: {}, // 搜尋的結果
      searching: false,
      searchType: "name",
      onlyView: false,
      memberOptionList: [],
      searchMemberDocId: null,
    };
  },
  methods: {
    // 停用會員
    async deleteMember(id, parentMemberId, name) {
      const type = "delete";

      if (confirm("請問您確定要停用這位會員嗎?")) {
        await memberService.deleteMember(id);

        if (this.searching === true) {
          this.set_ShowMembers_Status(id, type, parentMemberId, name); // 變更showMembers變數中的會員status
        } else {
          this.showMembers = this.showMembers.filter((el) => {
            return el.id !== id;
          });
        }

        this.set_ShowMembers_nextLevel1MembersId(
          id,
          type,
          parentMemberId,
          name
        ); // 調整該會員的上線的nextLevel1MembersId

        variableService.init_allMember();
      }
    },
    // 重啟會員
    async statusNormalMember(id, parentMemberId, name) {
      const type = "normal";

      if (confirm("確定要重啟這位會員嗎?")) {
        await memberService.statusNormalMember(id);
        this.set_ShowMembers_Status(id, type, parentMemberId, name); // 變更showMembers變數中的會員status
        this.set_ShowMembers_nextLevel1MembersId(
          id,
          type,
          parentMemberId,
          name
        ); // 調整該會員的上線的nextLevel1MembersId

        variableService.init_allMember();
      }
    },
    // 變更showMembers變數中的會員status
    set_ShowMembers_Status(id, type) {
      this.showMembers.forEach((e) => {
        if (e.id == id) {
          e.data.status = type;
        }
      });
    },
    // 變更showMembers1變數中會員的nextLevel1MembersId
    set_ShowMembers_nextLevel1MembersId(id, type, parentMemberId, name) {
      this.showMembers.forEach((e) => {
        if (e.id == parentMemberId) {
          if (type == "normal") {
            e.data.nextLevel1MembersId[id] = { name: name };
          } else if (type == "delete") {
            delete e.data.nextLevel1MembersId[id];
          }
        }
      });
    },
    async search() {
      if (this.searchKeyword) {
        this.showMembers = await memberService.searchMember(
          this.searchType,
          this.searchKeyword
        );
        this.searching = true;
      }
    },
    async searchByDocId() {
      if (!this.searchMemberDocId) {
        alert("請先從清單中選擇要搜尋的會員");
      } else {
        this.showMembers = await memberService.searchMemberByDocId(
          this.searchMemberDocId
        );

        this.searching = true;
      }
    },
    changeSerchType() {
      if (this.searching) {
        this.search();
      }
    },
    async noSearch() {
      this.searching = false;
      this.searchKeyword = "";
      // this.showMembers = await memberService.getAllMembers();
      this.showMembers = this.getMembers();
    },
    async getMembers() {
      const result = await memberService.getMembers();
      this.showMembers = result.members;
      this.lastDoc = result.lastDoc;
    },
    async loadMore() {
      const result = await memberService.getMembers(this.lastDoc);
      this.showMembers = this.showMembers.concat(result.members);
      this.lastDoc = result.lastDoc;
    },
  },
  async created() {
    // this.showMembers = await memberService.getAllMembers();
    this.getMembers();

    if (
      this.$store.state.adminUserRole === "memberAll_transcation" ||
      this.$store.state.adminUserRole === "memberAll_onlyview"
    ) {
      this.onlyView = true;
    }

    this.memberOptionList = await variableService.get_allMember_selectOption();
  },
  // watch: {
  //   showMembers: {
  //     handler(newValue, oldValue) {
  //       console.log("showMembers is :");
  //       console.log(newValue);
  //     },
  //     deep: true,
  //     immediate: true,
  //   }
  // },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
.multiselect {
  z-index: 9999;
  width: 80%;
  margin: 0 15px 0 0;
}
</style>
